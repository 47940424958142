import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PrivacyNotice = props => (
  <Layout {...props}>
    <Seo title='Privacy Notice' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Privacy Notice' />
        <Divider />
        <p>
          Football Today Ltd and its affiliated companies are dedicated to
          providing its users with transparency and control over the use of
          their data. In order for us to provide you with our services we may
          collect and process certain personal information about you and your
          activity.
        </p>
        <p>
          By entrusting us with your information, we would like to assure you of
          our commitment to keep such information private. We have taken
          measurable steps to protect the confidentiality, security and
          integrity of this Information. We encourage you to review the
          following information carefully.
        </p>
      </Main>
    </Stack>
  </Layout>
)

export default PrivacyNotice
